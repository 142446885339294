<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import {computed, onMounted, ref} from "@vue/composition-api";
import store from "@/store";
import heroSwiperStoreModule from "./store/heroSwiperStoreModule";
import i18n from "@/libs/i18n";
import {RequestBuilder} from "@core/utils/requestBuilder";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {required} from "@core/utils/validations/validations";
import ItemList from "@/views/components/whitelabel-templates/common/itemList.vue";
import ItemForm from "@/views/components/whitelabel-templates/common/itemForm.vue";
import {UseIndex} from "@/views/components/whitelabel-templates/common/composables/useIndex";

export default {
  name: "index",
  components: {
    ItemForm,
    ItemList,
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
  },

  props: {
    options: {
      type: Object,
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "responsible_gaming",
        visible: false,
      }),
    },
  },


  setup(props, {emit}) {
    const GM_HERO_SWIPER_STORE_MODULE_NAME = "gm-hero-swiper-store";
    const heroSwiper = ref({
      name: '',
      images: [],
    });

    const item = ref({
      href: "",
      src: ""
    });

    const rBuild = RequestBuilder();

    const {
      settings,
      editTemplate,
      showForm,
      enableEditMode,
      toast,
      loading,
      refFormObserver,
      getValidationState,
      enableAddMode,
      resetForm,
      showList,
      setIndex
    } = UseIndex();


    if (!store.hasModule(GM_HERO_SWIPER_STORE_MODULE_NAME))
      store.registerModule(GM_HERO_SWIPER_STORE_MODULE_NAME, heroSwiperStoreModule);

    onMounted(() => {
      resetHeroSwiper();
      const {itemsSelected} = props.options;
      if (itemsSelected) heroSwiper.value = itemsSelected;
      updatePreview();
    });


    const isEditMode = computed(() => props.options.action === "edit");

    const resetItem = () => {
      item.value = {
        href: "",
        src: ""
      };
    };

    const onEditItem = (val) => {
      showForm();
      enableEditMode();
      setIndex(val.index)
      item.value = val.item;
    };

    const onAddItem = () => {
      showForm();
      enableAddMode();
      resetItem();
      updatePreview();
    };

    const resetAll = (val) => {
      if (val) {
        loadItemFromCache(val)
      }
      resetItem();
      showList();
      updatePreview();
    };

    const loadItemFromCache = (val) => {
      heroSwiper.value.images[val.index] = val.item
    }


    const onDeleteItem = (index) => {
      heroSwiper.value.images.splice(index, 1)
      updatePreview();
    };


    const onSubmit = () => {
      if (isEditMode.value) {
        updateHeroSwiper();
      } else {
        createHeroSwiper();
      }
    };

    const updateTemplate = async () => {
      try {
        await store.dispatch("app-whitelabel-templates/updateTemplate", {
          template: editTemplate.value,
          toast,
          path: "templateData.heroSwiper"
        });
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    };

    const createHeroSwiper = async () => {
      loading.value = true;
      heroSwiper.value.whitelabelId =
          store.state.whitelabelCurrencyNabvar.whitelabel._id;
      heroSwiper.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
            "gm-hero-swiper-store/addHeroSwiperItem",
            rBuild.clean(heroSwiper.value)
        );

        showSuccessToast(toast, 'Hero Swiper', `Hero Swiper created`);
        updatePreview(response.data);
        await updateTemplate();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("Error creating or updating Hero Swiper"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const updateHeroSwiper = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch("gm-hero-swiper-store/updateHeroSwiperItem", {
          id: heroSwiper.value._id,
          heroSwiperData: rBuild.clean(heroSwiper.value),
        });

        showSuccessToast(toast, 'Hero Swiper', 'Hero Swiper created');
        updatePreview(response.data);
        await updateTemplate();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            'Error creating or updating Hero Swiper',
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const resetHeroSwiper = () => {
      heroSwiper.value = {
        name: '',
        images: [],
      };
    };

    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE", "templateData.heroSwiper");
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      emit("reset");
      updatePreview()
    };


    const updatePreview = (value) => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          heroSwiper: value || heroSwiper.value
        }
      };
      store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", updatedTemplate);
    };

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const onUploadedImage = (data) => {
      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        heroSwiper.value.images.push({src: image})
        updatePreview();
      }
    };

    const onClearImage = (index) => {
      heroSwiper.value.images.splice(index, 1)
      updatePreview();
    }

    const saveItem = () => {
      heroSwiper.value.images.push(item.value);
      updatePreview();
      showList();
      resetAll();
    };

    const updateItem = () => {
      heroSwiper.value.images[settings.value.index] = item.value
      updatePreview();
      showList();
      resetAll();
    };

    return {
      isEditMode,
      onSubmit,
      updateTemplate,
      resetData,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      heroSwiper,
      loading,
      updatePreview,
      errorInLoadImage,
      onUploadedImage,
      onClearImage,
      settings,
      onAddItem,
      onEditItem,
      onDeleteItem,
      resetAll,
      saveItem,
      updateItem,
      item
    };
  },
};
</script>

<template>
  <b-tabs content-class="mt-2" justified>
    <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
      <b-form
          v-if="heroSwiper"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
      >
        <b-tab title="Content" active>
          <b-list-group-item style="background: none" class="px-0">
            <b-row>
              <validation-provider
                  class="w-100"
                  #default="validationContext"
                  name="name"
                  rules="required"
              >
                <b-col cols="12">
                  <b-form-group label="name" label-for="h-name">
                    <template #label>{{ $t("labels.name") }}</template>
                    <b-form-input
                        id="h-name"
                        type="text"
                        v-model="heroSwiper.name"
                        @input="updatePreview()"
                        :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  </b-form-group>
                </b-col>
              </validation-provider>
            </b-row>
          </b-list-group-item>

        </b-tab>

        <b-tab title="Settings">
          <item-list
              :items="heroSwiper.images"
              v-if="settings.status === 'list'"
              @add="onAddItem()"
              :max-count="5"
              @edit="onEditItem($event)"
              @delete="onDeleteItem($event)"
          >
          </item-list>
          <item-form
              class="mt-1"
              @reset="resetAll($event)"
              @save="saveItem()"
              @update="updateItem()"
              @iconSelected="updatePreview()"
              :settings="settings"
              :item="item"
              size="1920x900"
              :size-validation="true"
              :template="editTemplate"
              v-if="settings.status === 'form'"
          >
          </item-form>
        </b-tab>

        <div class="mt-4 d-flex flex-column" style="gap: 5px">
          <b-button
              :disabled="loading"
              size="md"
              type="submit"
              class="w-100"
              variant="success"
          >
            {{
              isEditMode
                  ? $t("buttons.update_and_apply")
                  : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-tabs>
</template>

<style scoped lang="scss">
.close-btn {
  float: right;
  top: 5px;
}

</style>
