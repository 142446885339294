<script>
import {BButton, BCol, BFormGroup, BFormInput, BFormInvalidFeedback, BFormSelect, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import {computed, ref, watch} from "@vue/composition-api";
import ItemList from "@/views/components/whitelabel-templates/common/itemList.vue";
import {ValidationProvider} from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import {useToast} from "vue-toastification/composition";

const BACK_URL = process.env.VUE_APP_URL;

export default {
  name: "LeftSection",
  components: {
    ImageFieldForm,
    BFormSelect,
    ValidationProvider,
    ItemList, BRow, BButton, BFormInput, BFormInvalidFeedback, BFormGroup, BCol, vSelect
  },

  props: {
    type: null,
    items: [],
  },

  setup(props, {emit}) {
    const settings = ref({
      status: 'list',
    })

    const index = ref(null)
    const formValid = ref(false)
    const toast = useToast();

    const item = ref({
      content: ''
    })

    const onCancel = () => {
      item.value = {content: ''}
      settings.value.status = 'list'
      index.value = null
    }

    const itemHandler = () => {
      if (!formValid.value) {
        return
      }

      if (index.value !== null) {
        emit('editItem', {value: item.value, index: index.value})
      } else {
        emit('saveItem', item.value)
      }

      settings.value.status = 'list'
    }


    watch(() => item, () => {
      checkForm()
    }, {deep: true})


    const checkForm = () => {
      const required = ['content']
      formValid.value = false

      required.forEach(key => {
        if (item.value[key]) {
          formValid.value = true
        }
      })
    }

    const count = computed(() => {
      return props.items.length || 0;
    });

    const onEdit = (val) => {
      item.value = {...val.item}
      index.value = val.index
      settings.value.status = 'form'
    }

    const onDelete = (index) => {
      emit('deleteItem', index)
    }

    const onAdd = () => {
      index.value = null
      item.value = {content: ''}
      settings.value.status = 'form'
    }

    const onChangeItem = () => {
      emit('change', {item: item.value, index: index.value})
    }


    return {
      onCancel,
      formValid,
      itemHandler,
      onChangeItem,
      settings,
      item,
      onDelete,
      onEdit,
      count,
      onAdd
    }
  },

  created() {
  }
}
</script>

<template>
  <div>

    <div v-if="settings.status === 'form'">

      <b-col class="border-md-left" cols="12">
        <b-form-group label-for="text">
          <template #label>{{ $t("content") }}</template>
          <b-form-input
              @input="onChangeItem()"
              id="title"
              required
              type="text"
              v-model="item.content">

          </b-form-input>
        </b-form-group>
      </b-col>

      <div class="row px-1 pb-2">
        <div class="col-6">
          <b-button :disabled="!formValid"
                    class="w-100 mt-1"
                    @click="itemHandler"
                    variant="primary">
            save
          </b-button>
        </div>
        <div class="col-6">
          <b-button class="w-100 mt-1" variant="secondary" @click="onCancel()">
            {{ $t("buttons.cancel") }}
          </b-button>
        </div>
      </div>

    </div>

    <!--- ITEMS LIST -->
    <div class="mx-1 pb-2">
      <item-list
          :items="items"
          v-if="settings.status === 'list'"
          @add="onAdd()"
          :max-count="2"
          @edit="onEdit($event)"
          @delete="onDelete($event)">
      </item-list>
    </div>

  </div>
</template>

<style scoped lang="scss">
.item-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>