<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import {computed, onMounted, ref} from "@vue/composition-api";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import formValidation from "@core/comp-functions/forms/form-validation";
import {useToast} from "vue-toastification/composition";
import store from "@/store";
import headerStoreModule from "./store/headerStoreModule";
import i18n from "@/libs/i18n";
import {RequestBuilder} from "@core/utils/requestBuilder";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@core/utils/validations/validations";
import {UseIndex} from "@/views/components/whitelabel-templates/common/composables/useIndex";

export default {
  name: "FEditHeader",
  components: {
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
  },

  props: {
    options: {
      type: Object,
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "header",
        visible: false,
      }),
    },
  },

  setup(props, {emit}) {
    const HEADER_APP_STORE_MODULE_NAME = "gm-header-store";
    const header = ref({
      name: '',
      font_size: '',
      color: '',
      background_color: '',
      logo: {
        href: '',
      },
      auth: {
        hide: false,
        background_color: '',
        color: ''
      },
    });
    
    const debounceTimer = ref(null)

    const {
      editTemplate,
      toast,
      loading,
      refFormObserver,
      getValidationState,
      resetForm
    } = UseIndex();


    const rBuild = RequestBuilder();

    if (!store.hasModule(HEADER_APP_STORE_MODULE_NAME))
      store.registerModule(HEADER_APP_STORE_MODULE_NAME, headerStoreModule);

    const selectOptions = ref([
      {text: i18n.t("left"), value: 1},
      {text: i18n.t("center"), value: 2},
      {text: i18n.t("right"), value: 3},
    ]);

    onMounted(() => {
      resetHeader();
      const {itemsSelected} = props.options;
      if (itemsSelected) header.value = itemsSelected;
      updatePreview();
    });

    const isEditMode = computed(() => props.options.action === "edit");

    const onSubmit = () => {
      if (isEditMode.value) {
        updateHeader();
      } else {
        createHeader();
      }
    };

    const updateTemplate = async () => {
      try {
        await store.dispatch("app-whitelabel-templates/updateTemplate", {
          template: editTemplate.value,
          toast,
          path: "templateData.header"
        });
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    };

    const createHeader = async () => {
      loading.value = true;
      header.value.whitelabelId =
          store.state.whitelabelCurrencyNabvar.whitelabel._id;
      header.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
            "gm-header-store/addHeader",
            rBuild.clean(header.value)
        );

        showSuccessToast(toast, "Header", i18n.t("header_created"));
        updatePreview(response.data);
        await updateTemplate();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_update_header"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const updateHeader = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch("gm-header-store/updateHeader", {
          id: header.value._id,
          headerData: rBuild.clean(header.value),
        });

        showSuccessToast(toast, "Header", i18n.t("header_updated"));
        updatePreview(response.data);
        await updateTemplate();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_update_header"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const resetHeader = () => {
      header.value = {
        name: '',
        color: '',
        background_color: '',
        auth: {
          color: '',
          background_color: '',
          hide: false
        },
        logo: {
          href: ''
        }
      };
    };

    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE", "templateData.header");
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      emit("reset");
    };


    const onColorChange = ({ key, value }) => {
      const keys = key.split(".");
      let current = header.value;
      for (let i = 0; i < keys.length - 1; i++) {
        current = current[keys[i]];
      }
      current[keys[keys.length - 1]] = value;

      if (debounceTimer.value) {
        clearTimeout(debounceTimer.value);
      }

      debounceTimer.value = setTimeout(() => {
        updatePreview();
      }, 1000);
    };

    const updatePreview = (value) => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          header: value || header.value
        }
      };
      store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", updatedTemplate);
    };

    const onUploadedImage = (data, value) => {
      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        if (value === "background_image") {
          header.value.background_image = image || "";
        } else if (value === "logo") {
          header.value.logo.href = image || "";
        }
        updatePreview();
      }
    };

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    return {
      isEditMode,
      onSubmit,
      updateTemplate,
      resetData,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      selectOptions,
      header,
      loading,
      updatePreview,
      onColorChange,
      onUploadedImage,
      errorInLoadImage
    };
  },
};
</script>

<template>
  <b-tabs content-class="mt-2" justified>
    <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
      <b-form
          v-if="header"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
      >
        <b-tab title="Content" active>
          <b-list-group-item style="background: none" class="px-0">
            <b-row>
              <validation-provider
                  class="w-100"
                  #default="validationContext"
                  name="name"
                  rules="required"
              >
                <b-col cols="12">
                  <b-form-group label="name" label-for="h-name">
                    <template #label>{{ $t("labels.name") }}</template>
                    <b-form-input
                        id="h-name"
                        type="text"
                        v-model="header.name"
                        @input="updatePreview()"
                        :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  </b-form-group>
                </b-col>
              </validation-provider>
            </b-row>

            <b-row>
              <b-col cols="12">
                <validation-provider
                    #default="validationContext"
                    name="Color"
                    rules="required"
                >
                  <b-form-group
                      label="Color"
                      label-for="h-color"
                  >
                    <template #label>{{
                        $t("labels.color")
                      }}</template>
                    <b-form-input
                        id="h-color"
                        type="color"
                        :value="header.color"
                        @input="
                        (newValue) =>
                          onColorChange({
                            key: 'color',
                            value: newValue,
                          })
                      "
                        :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>


            <b-row>
              <b-col cols="12">
                <validation-provider
                    #default="validationContext"
                    name="BackgroundColor"
                    rules="required"
                >
                  <b-form-group
                      label="Background color"
                      label-for="h-backgroundColor"
                  >
                    <template #label>{{
                        $t("labels.backgroundcolor")
                      }}</template>
                    <b-form-input
                        id="h-backgroundColor"
                        type="color"
                        :value="header.background_color"
                        @input="
                        (newValue) =>
                          onColorChange({
                            key: 'background_color',
                            value: newValue,
                          })
                      "
                        :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

          </b-list-group-item>

          <b-list-group-item style="background: none" class="px-0">
            <h6 v-b-toggle.accordion-3>{{ $t("usermenu") }}</h6>
            <b-collapse
                id="accordion-3"
                accordion="my-accordion"
                role="tabpanel"
            >
              <div class="px-1 mt-1">
                <validation-provider
                    #default="validationContext"
                    name="ColorMenu"
                    class="w-100"
                >
                  <b-form-group label="ColorMenu" label-for="ColorMenu">
                    <template #label>{{ $t("color") }}</template>
                    <b-form-input
                        id="ColorMenu"
                        type="color"
                        :value="header.auth.color"
                        @input="
                              (newValue) =>
                                onColorChange({
                                  key: 'auth.color',
                                  value: newValue,
                                })
                            "
                        :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </div>

              <div class="px-1 mt-1">
                <validation-provider
                    #default="validationContext"
                    name="BackgroundMenu"
                    class="w-100"
                >
                  <b-form-group
                      label="BackgroundMenu"
                      label-for="BackgroundMenu"
                  >
                    <template #label
                    >{{ $t("labels.backgroundcolor") }}
                    </template>
                    <b-form-input
                        id="BackgroundMenu"
                        type="color"
                        :value="header.auth.background_color"
                        @input="
                              (newValue) =>
                                onColorChange({
                                  key: 'auth.background_color',
                                  value: newValue,
                                })
                            "
                        :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </div>

              <div class="px-1 mt-1">
                <validation-provider
                    #default="validationContext"
                    name="BackgroundMenu"
                    class="w-100"
                >
                  <b-form-group
                      label="BackgroundMenu"
                      label-for="BackgroundMenu"
                  >
                    <template #label>{{ $t("text_color") }}</template>
                    <b-form-input
                        id="BackgroundMenu"
                        type="color"
                        :value="header.auth.text_color"
                        @input="
                              (newValue) =>
                                onColorChange({
                                  key: 'auth.text_color',
                                  value: newValue,
                                })
                            "
                        :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </div>
            </b-collapse>
          </b-list-group-item>


          <!--------------------------------------------------------->

          <b-list-group-item style="background: none" class="px-0">
            <h6 v-b-toggle.accordion-2>Logo</h6>
            <b-collapse
                id="accordion-2"
                accordion="my-accordion"
                role="tabpanel"
            >
              <div class="px-1 mt-1">
                <image-field-form
                    :single-button="true"
                    class="w-100"
                    text="Logo"
                    :value="header.logo.href"
                    path="headers"
                    @uploadedImage="onUploadedImage($event, 'logo')"
                    @error="errorInLoadImage"
                    preview="true"
                >
                </image-field-form>

                <div class="row" v-if="header.logo.href.length > 0">
                  <div class="col">
                    <img
                        style="max-width: 100%"
                        :src="header.logo.href"
                        alt="Uploaded Image"
                        class="mt-1 rounded"
                    />

                    <b-button class="close-btn" @click="onClearImage()" variant="outline-primary" size="sm">
                      X
                    </b-button>
                  </div>
                </div>
              </div>
            </b-collapse>
          </b-list-group-item>

          <!--------------------------------------------------------->
        </b-tab>

<!--        <b-tab title="Settings">-->


<!--        </b-tab>-->

        <div class="mt-4 d-flex flex-column" style="gap: 5px">
          <b-button
              :disabled="loading"
              size="md"
              type="submit"
              class="w-100"
              variant="success"
          >
            {{
              isEditMode
                  ? $t("buttons.update_and_apply")
                  : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-tabs>
</template>

<style scoped lang="scss"></style>
