<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import {computed, onMounted, ref} from "@vue/composition-api";
import store from "@/store";
import RGamingStoreModule from "./store/rGamingStoreModule";
import i18n from "@/libs/i18n";
import {RequestBuilder} from "@core/utils/requestBuilder";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {required} from "@core/utils/validations/validations";
import {UseIndex} from "@/views/components/whitelabel-templates/common/composables/useIndex";

export default {
  name: "index",
  components: {
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
  },

  props: {
    options: {
      type: Object,
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "responsible_gaming",
        visible: false,
      }),
    },
  },

  setup(props, {emit}) {
    const RGAMING_APP_STORE_MODULE_NAME = "gm-gaming-store";
    const rGamingItem = ref({
      name: '',
      title: '',
      images: [],
      background_color: ''
    });

    const rBuild = RequestBuilder();
    const debounceTimer = ref(null);

    const {
      editTemplate,
      toast,
      loading,
      refFormObserver,
      getValidationState,
      resetForm,
    } = UseIndex();

    // Register modules
    if (!store.hasModule(RGAMING_APP_STORE_MODULE_NAME))
      store.registerModule(RGAMING_APP_STORE_MODULE_NAME, RGamingStoreModule);

    onMounted(() => {
      resetRGamingItem();
      const {itemsSelected} = props.options;
      if (itemsSelected) rGamingItem.value = itemsSelected;
      updatePreview();
    });


    const isEditMode = computed(() => props.options.action === "edit");

    const onSubmit = () => {
      if (isEditMode.value) {
        updateRGamingItem();
      } else {
        createRGamingItem();
      }
    };

    const updateTemplate = async () => {
      try {
        await store.dispatch("app-whitelabel-templates/updateTemplate", {
          template: editTemplate.value,
          toast,
          path: "templateData.responsible_gaming"
        });
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    };

    const createRGamingItem = async () => {
      loading.value = true;
      rGamingItem.value.whitelabelId =
          store.state.whitelabelCurrencyNabvar.whitelabel._id;
      rGamingItem.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
            "gm-gaming-store/addRGamingItem",
            rBuild.clean(rGamingItem.value)
        );

        showSuccessToast(toast, i18n.t('responsible_gaming'), `r_gaming_created`);
        updatePreview(response.data);
        await updateTemplate();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_update_r_gaming"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const updateRGamingItem = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch("gm-gaming-store/updateRGamingItem", {
          id: rGamingItem.value._id,
          rGamingData: rBuild.clean(rGamingItem.value),
        });

        showSuccessToast(toast, i18n.t('responsible_gaming'), i18n.t("r_gaming_updated"));
        updatePreview(response.data);
        await updateTemplate();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_update_r_gaming"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const resetRGamingItem = () => {
      rGamingItem.value = {
        name: '',
        title: '',
        images: [],
        background_color: ''
      };
    };

    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE", "templateData.responsible_gaming");
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      emit("reset");
    };


    const updatePreview = (value) => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          responsible_gaming: value || rGamingItem.value
        }
      };
      store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", updatedTemplate);
    };

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const onUploadedImage = (data) => {
      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        rGamingItem.value.images.push({src: image, href: ''})
        updatePreview();
      }
    };

    const onClearImage = (index) => {
      rGamingItem.value.images.splice(index, 1)
      updatePreview();
    }

    const onColorChange = ({key, value}) => {
      const keys = key.split(".");
      let current = rGamingItem.value;
      for (let i = 0; i < keys.length - 1; i++) {
        current = current[keys[i]];
      }
      current[keys[keys.length - 1]] = value;

      if (debounceTimer.value) {
        clearTimeout(debounceTimer.value);
      }

      debounceTimer.value = setTimeout(() => {
        updatePreview();
      }, 500);
    };


    return {
      isEditMode,
      onSubmit,
      updateTemplate,
      onColorChange,
      resetData,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      rGamingItem,
      onClearImage,
      loading,
      updatePreview,
      errorInLoadImage,
      onUploadedImage
    };
  },
};
</script>

<template>
  <b-tabs content-class="mt-2" justified>
    <validation-observer ref="refFormObserver" #default="{ handleSubmit }">
      <b-form
          v-if="rGamingItem"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
      >
        <b-tab title="Content" active>
          <b-list-group-item style="background: none" class="px-0">
            <b-row>
              <validation-provider
                  class="w-100"
                  #default="validationContext"
                  name="name"
                  rules="required"
              >
                <b-col cols="12">
                  <b-form-group label="name" label-for="h-name">
                    <template #label>{{ $t("labels.name") }}</template>
                    <b-form-input
                        id="h-name"
                        type="text"
                        v-model="rGamingItem.name"
                        @input="updatePreview()"
                        :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  </b-form-group>
                </b-col>
              </validation-provider>
            </b-row>

            <b-row>
              <validation-provider
                  class="w-100"
                  #default="validationContext"
                  name="name"
                  rules="required"
              >
                <b-col cols="12">
                  <b-form-group label="title" label-for="g-title">
                    <template #label>{{ $t("title") }}</template>
                    <b-form-textarea
                        rows="4"
                        id="g-title"
                        type="text"
                        v-model="rGamingItem.title"
                        @input="updatePreview()"
                        :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  </b-form-group>
                </b-col>
              </validation-provider>
            </b-row>


            <b-row>
              <b-col cols="12">
                <validation-provider
                    #default="validationContext"
                    name="BackgroundColor"
                    rules="required"
                >
                  <b-form-group
                      label="Background color"
                      label-for="h-backgroundColor"
                  >
                    <template #label>{{
                        $t("labels.backgroundcolor")
                      }}
                    </template>
                    <b-form-input
                        id="h-backgroundColor"
                        type="color"
                        :value="rGamingItem.background_color"
                        @input="
                        (newValue) =>
                          onColorChange({
                            key: 'background_color',
                            value: newValue,
                          })
                      "
                        :state="getValidationState(validationContext)"
                    />
                    <small class="text-danger">
                      {{ validationContext.errors[0] }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <b-row>
              <div class="px-1 w-100 mt-1">
                <div v-if="rGamingItem.images.length < 2">
                  <span>329x329</span>
                  <image-field-form
                      :single-button="true"
                      class="w-100"
                      text="Images"
                      :value="rGamingItem.images"
                      size-validation="329x329"
                      path="responsible_gaming"
                      @uploadedImage="onUploadedImage($event, 'images')"
                      @error="errorInLoadImage"
                      :preview="false"
                  >
                  </image-field-form>
                </div>

                <div class="row" v-if="rGamingItem.images">
                  <div v-for="(img, index) in rGamingItem.images" :key="index" class="col">
                    <img
                        v-if="img"
                        style="max-width: 100%"
                        :src="img.src"
                        alt="Uploaded Image"
                        class="mt-1 rounded"
                    />

                    <b-button class="close-btn" @click="onClearImage(index)" variant="outline-primary" size="sm">
                      X
                    </b-button>
                  </div>
                </div>
              </div>
            </b-row>

          </b-list-group-item>

        </b-tab>

        <div class="mt-4 d-flex flex-column" style="gap: 5px">
          <b-button
              :disabled="loading"
              size="md"
              type="submit"
              class="w-100"
              variant="success"
          >
            {{
              isEditMode
                  ? $t("buttons.update_and_apply")
                  : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-tabs>
</template>

<style scoped lang="scss">
.close-btn {
  float: right;
  top: 5px;
}
</style>
